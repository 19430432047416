<template>
  <v-container
    id="verifyemail"
    class="fill-height justify-center"
    tag="section"
  >
    <v-card 
      class="mt-16"
      elevation="0"
      max-width="600"
    >
      <v-card-text>
        <h2 class="mb-5">Thanks for conforming your email !</h2>
        <p style="line-height: 28px;">
          Revamp Cybersecurity will now begin setting up your dashboard.
        </p>
        <p style="line-height: 28px;">
          Please note it might take up to 24 hours for us to deliver set it up. You will receive an email once its ready.
        </p>
        <div class="d-flex align-center justify-center">
          <v-btn @click="learnMore" color="primary" text>Learn More</v-btn>
          <v-btn to="/" color="primary" text>Login</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
  export default {
    name: 'VerifyEmail',
    methods: {
      learnMore() {
        this.$router.push({path: '/help/get-started'})
      }
    }
  }
</script>